import React, { useState, useEffect } from 'react'
import { USER_LOGIN, USER_CONFIRM_ACCOUNT } from '../Graphql/Mutations'
import { useMutation, useApolloClient } from '@apollo/client'
import { useLocation, useNavigate, useLoaderData } from 'react-router-dom'
import image from '../Images/Homeprint-login.jpeg'
import handle_errors from '../Helpers/auth_errors'
import en from '../Locales/en.json'

export function loader({request}){
    return new URL(request.url).searchParams.get("message")
}

function Login(props) {
    const client = useApolloClient()
    const navigate = useNavigate()
    const location = useLocation()
    const message = useLoaderData()

    // State to save user data input from the form
    const [formData, setFormData] = useState({
        emailAddress: '',
        password: '',
        isRemembered: false,
    })

    // destructuring mutation data
    const [userLogin, { data, loading, error }] = useMutation(USER_LOGIN)
    const [userConfirmAccount, { confirmData }] = useMutation(USER_CONFIRM_ACCOUNT)

    const [verifyError, setVerifyError] = useState()
    // Function handles changes to form fields and updates component state with those changes
    function handleChange(event) {
        const { name, value, type, checked } = event.target
        setFormData((prevFormData) => {
            return {
                ...prevFormData,
                [name]: type === 'checkbox' ? checked : value,
            }
        })
    }

    function login() {
        userLogin({
            variables: {
                email: formData.emailAddress,
                password: formData.password,
            },
            onCompleted: (data) => {
                localStorage.setItem('credentials', JSON.stringify(data.userLogin.credentials))
                client.resetStore()
                sessionStorage.clear()
                props.setLogged(true)
            },
            onError: (operationError) => {
                if(operationError?.message?.includes('Invalid login credentials')){return}//prevent redundant error ux
                console.error('Error at login: ', operationError)
                operationError?.networkError 
                    ? navigate('/login?message=No connection: Please try again when you have better network coverage')
                    : navigate('/login?message=We are experiencing technical difficulties at the moment. Please try again later or contact us if the issue persists')
            }
        })
    }

    function move() { // I hate this
        navigate('/login')
    }

    // Handles the login form submission by sending a mutation request to the Graphql server
    function handleSubmit(e) {
        e.preventDefault()
        login()
    }

    useEffect(() => {
        let params = new URLSearchParams(window.location.search);
        let t = params.get('t');
        if(location.pathname == '/confirm' && t){
            userConfirmAccount({
                variables: {
                    apiKey: process.env.REACT_APP_API_KEY,
                    token: t,
                },
                onCompleted: (confirmData) => {
                    if (confirmData.userConfirmAccount.errors) {
                        setVerifyError({graphQLErrors: [{extensions: {detailed_errors: confirmData.userConfirmAccount.errors}}]})
                    }
                },
                onError: (e) => {  }
            })
        }
    }, [])

    return (
        <>
            {/* wrapper */}
            <div className="font-int flex  h-screen flex-col bg-white lg:relative">
                {/* container of body and image */}
                
                <div className="flex max-w-full flex-grow flex-col md:flex-row">
                
                    {/* container for vertical alignment and large screens in 2 */}
                    <div className="flex h-full flex-col items-center justify-center md:w-1/2">
                        
                        
                        {!location.pathname.includes('confirm') 
                            ? <div className="max-w-sm px-3 py-12 sm:mx-0 sm:pt-12 sm:pb-12 lg:py-16 2xl:max-w-lg">
                                {/* title */}
                                    <div className="flex justify-center items-center mb-20">
                                        <div 
                                            className="self-center h-12 w-full bg-[url('/public/homeprint.png')] bg-center bg-contain bg-no-repeat">                                    
                                        </div>
                                    </div>
                                    <div>
                                        <h1 className="my-6 text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl sm:tracking-tight 2xl:whitespace-nowrap 2xl:text-7xl">
                                            {/* Log in */}
                                            {en.login.title}
                                        </h1>
                                        <p className="text-center text-base font-normal text-[#667085]">
                                            {/* Hello there! Please enter your details to log in. */}
                                            {en.login.message}
                                        </p>
                                        { message && 
                                            <h2 className="mt-6 text-red-500 text-base text-center first-letter:uppercase font-semibold"> 
                                                {message} 
                                            </h2>
                                        }
                                    </div>
                                
                                <form
                                    className={`${message ? 'mt-1' : 'mt-6'} max-w-sm md:max-w-md 2xl:max-w-lg 2xl:text-base`}
                                    onSubmit={handleSubmit}
                                >
                                    {/* email address */}
                                    <div className="flex flex-col py-2">
                                        <label
                                            htmlFor="emailAddress"
                                            className="mb-1 inline-block text-sm font-medium text-[#344054] 2xl:text-base"
                                        >
                                            {/* Email* */}
                                            {en.login.email}
                                        </label>
                                        <input
                                            type="text"
                                            name="emailAddress"
                                            id="emailAddress"
                                            autoComplete="email"
                                            className="border-blue-gray-300 col-span-2 inline-block rounded-md border border-solid py-2 px-1 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm 2xl:text-base"
                                            onChange={handleChange}
                                            value={formData.emailAddress}
                                        />
                                    </div>
                                    {/* Password */}
                                    <div className="flex flex-col py-2">
                                        <label
                                            htmlFor="password"
                                            className="mb-1 inline-block text-sm font-medium text-[#344054] 2xl:text-base"
                                        >
                                            {/* Password* */}
                                            {en.login.password}
                                        </label>
                                        <input
                                            type="password"
                                            name="password"
                                            id="password"
                                            autoComplete="current-password"
                                            className="border-blue-gray-300 col-span-2 inline-block rounded-md border border-solid py-2 px-1 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm 2xl:text-base"
                                            onChange={handleChange}
                                            value={formData.password}
                                        />
                                    </div>
                                    {/* Verification Token */}
                                    {location.pathname.includes('confirm') && <div className="flex flex-col py-2">
                                        <label
                                            htmlFor="token"
                                            className="mb-1 inline-block text-sm font-medium text-[#344054] 2xl:text-base"
                                        >
                                            {/* Verification code */}
                                            {en.login.confirm.verificationCode}
                                        </label>
                                        <input
                                            id="token"
                                            type="text"
                                            name="userToken"
                                            value={formData.userToken || ''}
                                            onChange={handleChange}
                                            className="border-blue-gray-300 col-span-2 inline-block rounded-md border border-solid py-2 px-1 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm 2xl:text-base"
                                            required
                                        />
                                    </div>
                                    }
                                    {/* Remember me checkbox and forgot password*/}
                                    <div className="flex justify-between">
                                        {/* checkbox wrapper */}
                                        <div>
                                            <input
                                                className="rounded border border-solid border-[#D0D5DD]"
                                                type="checkbox"
                                                id="isRemembered"
                                                name="isRemembered"
                                                checked={formData.isRemembered}
                                                onChange={handleChange}
                                            />
                                            <label
                                                htmlFor="isRemembered"
                                                className="ml-1.5 text-sm font-normal text-[#344054] 2xl:text-base"
                                            >
                                                {/* Remember me */}
                                                {en.login.rememberMe}
                                            </label>
                                        </div>
                                        {/* Forgot password */}
                                        <a
                                            href="/forgot-password"
                                            className="text-sm font-medium text-[#6941C6] 2xl:text-base"
                                        >
                                            {/* Forgot password */}
                                            {en.login.linkForgotPswd}
                                        </a>
                                    </div>
                                    
                                    <div className="sm:pt-3">{ location.pathname == '/confirm' && handle_errors(verifyError) }</div>
                                    <div className="sm:pt-3">{ location.pathname == '/login' && handle_errors(error) }</div>
                                    {/* Button's container */}
                                    <div className="mx-auto mt-8 flex flex-col md:max-w-sm 2xl:max-w-lg">
                                        {/* 'Create account' button */}
                                        <button
                                            className="mb-3 btn-primary-lg 2xl:text-lg"
                                            type="submit"
                                        >
                                            {en.login.submitBtn}
                                        </button>
                                        {/* 'Sign Up with Google' button */}
                                        {/* <button className="mb-3 rounded-lg border border-solid border-gray-300 bg-white py-3 px-5 text-center text-base font-medium text-gray-700 hover:text-indigo-500 2xl:text-lg">
                                            Sign in with Google
                                        </button> */}
                                        <div>
                                            <p className="text-center">
                                                {/* Don't have an account? */}
                                                {en.login.noAccount}
                                                <a href="/signup" className="text-[#6941C6] ml-1">
                                                    {/* Sign up */}
                                                    {en.login.linkSignUp}
                                                </a>
                                            </p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            : 
                            
                                <div className="max-w-sm px-3 py-12 sm:mx-0 sm:pt-12 sm:pb-12 sm:max-w-md lg:py-16 lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl">
                                {/* title */}
                                
                                    <div className='mx-auto'>
                                        <h1 className="my-6 text-center text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight xl:text-5xl 2xl:whitespace-nowrap 2xl:text-6xl">
                                            {en.accountConfirmation.title} 
                                        </h1>

                                        <p className="text-center text-base font-normal text-[#667085] lg:max-xl:max-w-sm">
                                            {en.accountConfirmation.message}
                                        </p>
                                    </div>
                                
                                    <div
                                        className="mx-auto mt-6 max-w-sm md:max-w-md 2xl:max-w-lg 2xl:text-base"
                                    >
                                        <div className="mx-auto mt-8 flex flex-col md:max-w-sm 2xl:max-w-lg">
                                            <button
                                                className="mb-3 btn-primary-lg 2xl:text-lg"
                                                onClick={move}
                                            >
                                                {en.accountConfirmation.loginBtn}
                                            </button>
                                        </div>
                                    </div>
                            </div>
                        }
                    </div> 
                    {/* photo */}
                    <div className="hidden bg-[#F5FBFF] md:flex md:w-1/2">
                        <img
                            className="object-cover md:object-right"
                            src={image}
                            alt="Living room displaying a trendy road bicycle"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login
